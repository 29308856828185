import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { FeatureFlag, FeatureFlagNameEnum } from '@keyops-cep/api-client';
import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';
import i18n from '../utils/i18n';

import useUserProfileData from './useUserProfileData';

type Flags = {
  [key in FeatureFlagNameEnum]?: boolean;
};

const useFeatureFlags = () => {
  const toast = useToast();
  const {
    data: userProfileData,
    isLoading: userProfileLoading,
    isError: userProfileError,
  } = useUserProfileData();

  const response = useQuery({
    queryKey: [`feature-flags-${userProfileData?.id}`],
    queryFn: async (): Promise<Flags> => {
      if (userProfileLoading || userProfileError || !userProfileData.tenantId) {
        return {};
      }
      const response = await apiClient.tenantsApi.tenantControllerFeatureFlags(
        userProfileData.tenantId
      );
      const flags = response?.data?.reduce(
        (flags: Flags, item: FeatureFlag) => {
          flags[item.name] = item.enabled;
          return flags;
        },
        {} as Flags
      );

      return flags;
    },
  });

  // Error handling for feature flag
  useEffect(() => {
    if (response.isError) {
      toast({
        title: i18n.t('errorComponent.title'),
        description: i18n.t('errorComponent.subTitle'),
        variant: 'subtle',
        isClosable: true,
        status: 'error',
        position: 'top-right',
      });
    }
  }, [response.isError, toast]);

  return response;
};

export default useFeatureFlags;
