import { useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { MessageDto } from '@keyops-cep/api-client';

import { useSurveyDraftingAI } from '../../hooks/useSurveyDraftingAI';
import { ANALYTICS_EVENT_NAME } from '../../utils/constants/analytics-constants';
import { Role } from '../../utils/constants/engage-ai';
import { ChatInput } from '../EngagementDetails/components/chat/ChatInput';
import { ChatMessages } from '../EngagementDetails/components/chat/ChatMessages';

import { InitialChatHeader } from './components/InitialChatHeader';

export const SurveyDrafter = () => {
  const chatMessagesRef = useRef<{ focusLastMessage: () => void }>(null);

  const {
    messages,
    isSendingMessage,
    isCheckingStatus,
    errorMessage,
    startChatSession,
    sendSurveyDraftingAIMessage,
  } = useSurveyDraftingAI();
  useEffect(() => {
    window.analytics.track(ANALYTICS_EVENT_NAME.ENGAGE_AI.OPEN_SURVEY_DRAFT);
    startChatSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentMessage, setCurrentMessage] = useState<MessageDto>({
    role: Role.user,
    content: '',
    createdAt: Date.now().toLocaleString(),
  });

  const handleSendMessage = () => {
    // Prevent new message
    if (
      isSendingMessage ||
      isCheckingStatus ||
      currentMessage.content.trim() === ''
    ) {
      return;
    }
    sendSurveyDraftingAIMessage(currentMessage);
    // Empty the current message now it's sent
    setCurrentMessage({
      role: Role.user,
      content: '',
      createdAt: Date.now().toLocaleString(),
    });
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGE_AI.SEND_SURVEY_DRAFT_MESSAGE,
      {
        message: currentMessage,
      }
    );
  };
  return (
    <>
      <Flex direction={'column'}>
        <InitialChatHeader />
        <ChatMessages
          messages={messages}
          errorMessage={errorMessage}
          ref={chatMessagesRef}
        />
        <ChatInput
          currentMessage={currentMessage}
          setCurrentMessage={setCurrentMessage}
          handleSendMessage={handleSendMessage}
        />
      </Flex>
    </>
  );
};
