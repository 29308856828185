import { AdBoardEngagement } from '@keyops-cep/api-client';

export const AdBoardStates = ['draft', 'planning', 'live', 'closed'] as const;
export type AdBoardState = (typeof AdBoardStates)[number];

export type AdBoardOutletContextType = {
  adBoardEngagementDetails: AdBoardEngagement;
  adBoardData: AdBoardDto;
  executiveSummaryData: {
    attendees: number;
    completionRate: string;
    executiveSummaryIntro: string;
    executiveSummaryRecommendations: string;
  };
  feedData: Array<AdBoardEngagementFeedDto>;
  participantsData: Array<AdBoardEngagementParticipantsDto>;
};

// TODO: use generated styles
export type AdBoardDto = {
  id: string;
  title: string;
  description?: string;
  objectives: string;
  startDate?: Date;
  endDate?: Date;
  createdAt: Date;
  updatedAt: Date;
  sections: SectionDto[];
  sectionGroups: AdBoardSectionGroupDto[];
  state: AdBoardState;
};

export type CompanyDto = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};

type BaseAdBoardSectionDto = {
  id: string;
  title: string;
  description?: string;
  index: number;
  adBoardId: string;
  sectionGroupId: string;
};

export type VideoDto = {
  id: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
  description?: string;
  link: string;
  totalRunTime: number; // store in seconds, e.g. a 10 minute video would be 600
};

export type AttachmentDto = {
  id: string;
  title: string;
  description?: string;
  contentType: unknown;
  readingTime: number;
  link: string;
  createdAt?: string;
  updatedAt?: string;
};

export type AttachmentFormDto = {
  title: string;
  description?: string;
  readingTime: number;
  file: File;
};

export type SurveyDto = {
  id: string;
  surveySparrowId: string;
  surveyChannelId?: string;
  createdAt: string;
  updatedAt: string;
};

export type DiscussionDto = {
  id: string;
  topic: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  messages?: DiscussionMessageDto[];
};

export type DiscussionMessageDto = {
  id: string;
  content: string;
  discussionId: string; // The discussion it belongs to
  // The message author can either be an HCP or an admin (moderator in the future)
  userId?: string;
  user?: Partial<unknown>; // We may need some of the author info
  adminId?: string;
  admin?: Partial<unknown>; // We may need some of the author info
  createdAt: string;
  updatedAt: string;
};

export type AllAdBoardDiscussionsDto = {
  generalDiscussion: DiscussionDto;
  discussionsSections: DiscussionsAdBoardSectionDto[];
};

export type VideoAdBoardSectionDto = BaseAdBoardSectionDto & {
  type: 'video';
  content: { id: string; videoId: string; video: VideoDto };
};

export type AttachmentAdBoardSectionContentDto = {
  id: string;
  attachmentId: string;
  index: number;
  attachment: AttachmentDto;
};

export type AttachmentsAdBoardSectionDto = BaseAdBoardSectionDto & {
  type: 'attachment';
  content: AttachmentAdBoardSectionContentDto[];
};

export type SurveyAdBoardSectionDto = BaseAdBoardSectionDto & {
  type: 'survey';
  content: {
    id: string;
    surveyId: string;
    survey: SurveyDto;
    surveyEngagementId?: string;
  };
};

// Discussion section
export type DiscussionAdBoardSectionContentDto = {
  id: string;
  discussionId: string;
  index: number;
  discussion: DiscussionDto;
};
export type DiscussionsAdBoardSectionDto = BaseAdBoardSectionDto & {
  type: 'discussion';
  content: DiscussionAdBoardSectionContentDto[];
};

export type SectionDto =
  | AttachmentsAdBoardSectionDto
  | DiscussionsAdBoardSectionDto
  | SurveyAdBoardSectionDto
  | VideoAdBoardSectionDto;

export type AdBoardSectionGroupDto = {
  id: string;
  title: string;
  description?: string;
  index: number;
  adBoardId: string;
  estimatedTime: number;
  createdAt: string;
  updatedAt: string;
  sections?: SectionDto[];
};

export type DiscussionMessageContextDto = {
  mentions: string[]; // array of userIds, empty if not mentions
};

export type VideoMessageContextDto = DiscussionMessageContextDto & {
  videoTimeStamp: number;
};

export type DocumentMarkupMessageContextDto = DiscussionMessageContextDto & {
  startIndex: number;
  endIndex: number;
};

export type MessageContext =
  | DiscussionMessageContextDto
  | VideoMessageContextDto
  | DocumentMarkupMessageContextDto;

export const AdBoardSectionTypes = [
  'attachment',
  'discussion',
  'video',
  'survey',
  'documentMarkup',
] as const;

export type AdBoardSectionType = (typeof AdBoardSectionTypes)[number];

export type AdBoardEngagementFeedDto = {
  id: number;
  userId: number;
  participantName: string;
  createdAt: string;
  sectionId: string;
  sectionIndex: number;
  sectionTitle: string;
  sectionType: AdBoardSectionType;
  sectionGroupId: string;
  sectionGroupIndex: number;
  sectionGroupTitle: string;
  content?: string;
  discussionId?: string;
  attachmentName?: string;
  attachmentOpenedAt?: string;
  messageContext?: MessageContext;
};

export type AdBoardEngagementParticipantsDto = {
  adBoardId: string;
  completionPercentage: number;
  honorarium: number;
  id: string;
  inviteId: string;
  userId: number;
  progress: AdBoardProgressDto;
  createdAt: string;
  updatedAt: string;
  user?: { firstName: string; lastName: string; speciality?: string[] }; // Only firstName, lastName and specialty
};

export type AdBoardProgressDto = {
  id: string;
  latestSection?: string;
  progressDetails: ProgressDetails;
  version: string;
  startedOn?: string;
  finishedOn?: string;
  createdAt: string;
  updatedAt: string;
};

// Base progress type
type BaseAdBoardSectionProgress = {
  sectionId: string;
  finished?: boolean;
  accessible: boolean;
};

// Extension progress types
export type AdBoardVideoSectionProgressDto = { currentTime: number };
type FinishedAttachments = { attachmentId: string; timestamp: string };
export type AdBoardAttachmentSectionProgressDto = {
  finishedAttachments: FinishedAttachments[];
};
export type AdBoardSurveySectionProgressDto = { uniqueLink: string };
export type AdBoardDiscussionSectionProgressDto = { finishedTopics: string[] };

// Intersection for each specific section progress type
export type AdBoardVideoSectionProgressCombined = BaseAdBoardSectionProgress &
  AdBoardVideoSectionProgressDto;
export type AdBoardAttachmentSectionProgressCombined =
  BaseAdBoardSectionProgress & AdBoardAttachmentSectionProgressDto;
export type AdBoardSurveySectionProgressCombined = BaseAdBoardSectionProgress &
  AdBoardSurveySectionProgressDto;
export type AdBoardDiscussionSectionProgressCombined =
  BaseAdBoardSectionProgress & AdBoardDiscussionSectionProgressDto;

// Union of the two combined types
export type AdBoardSectionProgressDto =
  | AdBoardVideoSectionProgressCombined
  | AdBoardAttachmentSectionProgressCombined
  | AdBoardSurveySectionProgressCombined
  | AdBoardDiscussionSectionProgressCombined;

interface ProgressDetails {
  sectionProgress: AdBoardSectionProgressDto[];
}

export const isVideoMessageContext = (
  messageContext: MessageContext | undefined
): messageContext is VideoMessageContextDto => {
  return !!(
    messageContext &&
    (messageContext as VideoMessageContextDto).videoTimeStamp !== undefined
  );
};
