export const ANALYTICS_EVENT_NAME = {
  AUTH: {
    AZURE_SIGNUP_CLICK: 'Azure AD Signup Click',
    AZURE_API_SIGNUP: 'Azure Signup to Api',
    AZURE_API_SIGNUP_SUCCESS: 'Azure Signup to Api - Success',
    AZURE_API_SIGNUP_ERROR: 'Azure Signup to Api - Error',
    AZURE_LOGIN_CLICK: 'Azure AD Login Click',
    AZURE_AD_LOGIN: 'Login to Azure AD',
    AZURE_API_LOGIN: 'Azure Login to Api',
    AZURE_API_LOGIN_SUCCESS: 'Azure Login to Api - Success',
    AZURE_API_LOGIN_ERROR: 'Azure Login to Api - Error',
    EMAIL_PASSWORD_SIGNUP_CLICK: 'Email-Password Signup Click',
    EMAIL_PASSWORD_API_SIGNUP: 'Email-Password Signup to Api',
    EMAIL_PASSWORD_API_SIGNUP_SUCCESS: 'Email-Password Signup to Api - Success',
    EMAIL_PASSWORD_API_SIGNUP_ERROR: 'Email-Password Signup to Api - Error',
    EMAIL_PASSWORD_LOGIN_CLICK: 'Email-Password Login Click',
    EMAIL_PASSWORD_API_LOGIN: 'Email-Password Login to Api',
    EMAIL_PASSWORD_API_LOGIN_SUCCESS: 'Email-Password Login to Api - Success',
    EMAIL_PASSWORD_API_LOGIN_ERROR: 'Email-Password Login to Api - Error',
    GOOGLE_LOGIN_CLICK: 'Google Login Click',
    GOOGLE_API_LOGIN: 'Google Login to Api',
    GOOGLE_API_LOGIN_SUCCESS: 'Google Login to Api - Success',
    GOOGLE_API_LOGIN_ERROR: 'Google Login to Api - Error',
    LOGOUT: 'Logout',
  },
  DASHBOARD: {
    ENGAGEMENT_LIST_SEARCH: 'Engagements List Search',
    ENGAGEMENT_LIST_VIEW_BUTTON_CLICK: 'Engagements List - View Button Click',
    ENGAGEMENT_LIST_COMPARE_BUTTON_CLICK:
      'Engagements List - Compare Button Click',
  },
  ENGAGEMENT_DETAILS: {
    EXPORT_DATA: 'Engagements Details - Export Data',
    SEARCH: 'Engagement Details - Search',
    ADD_FILTER: 'Engagement Details - Add Filter',
    REMOVE_FILTER: 'Engagement Details - Remove Filter',
    CLEAR_ALL_FILTER: 'Engagement Details - Clear All Filters',
    VISUALIZATION_EXPAND: 'Engagement Details - Visualization Expand',
    EXPANDED_VISUALIZATION_DOWNLOAD:
      'Engagement Details - Expanded Visualization Download',
    EXPANDED_VISUALIZATION_COPY:
      'Engagement Details - Expanded Visualization Copy',
    VISUALIZATION_DOWNLOAD: 'Engagement Details - Visualization Download',
    VISUALIZATION_COPY: 'Engagement Details - Visualization Copy',
    ANALYSIS_COPY: 'Engagement Details - Analysis Copy',
  },
  ENGAGE_AI: {
    OPEN_CHAT: 'Open AI Chat',
    SEND_MESSAGE: 'Send AI Message',
    OPEN_FAQ: 'Open AI FAQ',
    OPEN_SURVEY_DRAFT: 'survey_draft_opened',
    SEND_SURVEY_DRAFT_MESSAGE: 'survey_draft_message_sent',
  },
  ENGAGEMENTS_COMPARISON: {
    TOGGLE_ENGAGEMENTS_DETAILS_TAB:
      'Engagements Comparison - Engagements Details Tab',
    TOGGLE_COMPARATIVE_ANALYSIS_TAB:
      'Engagements Comparison - Comparative Analysis Tab',
    NO_COMPARABLE_ENGAGEMENTS:
      'Engagements Comparison - No Comparable Engagements Inquiry Email',
    COMPARABLE_ENGAGEMENTS_DROPDOWN:
      'Engagements Comparison - Comparison Selected',
    QUESTION_COMPARISON_REPORT:
      'Engagements Comparison - Viewing Question Comparison Report',
  },
};
