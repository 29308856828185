import { useState } from 'react';
import { MessageDto } from '@keyops-cep/api-client';

import { apiClient } from '../api/swagger-codegen-api-config';
import { LoadingThreeDots } from '../pages/EngagementDetails/components/chat/LoadingThreeDots';
import { ChatStatus, Role } from '../utils/constants/engage-ai';
import { initialSurveyDraftingMessages } from '../utils/constants/survey-drafting-constants';
import { isChatStatusError } from '../utils/functions/engage-ai.utils';
import i18n from '../utils/i18n';

export const useSurveyDraftingAI = () => {
  const [messages, setMessages] = useState<MessageDto[]>(
    initialSurveyDraftingMessages
  );
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  /*
   * Start chat session when the page is opened
   * Useful to display or not initial information in the chat
   */
  //   TODO: use isLoading in the chat to inform we fetch the pre-exisiting session
  const [hasInteractedYet, setHasInteractedYet] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const startChatSession = async () => {
    try {
      setIsLoading(true);
      const result =
        await apiClient.surveyDraftingApi.surveyDraftingControllerCreate();
      if (result?.data) {
        setThreadId(result.data.threadId);
      }
    } catch (error) {
      setErrorMessage(i18n.t('engagementDetails.engageAi.apiError'));
    } finally {
      setIsLoading(false);
    }
  };

  /*
   * Sends a message to ChatGPT, retrieve an openai run id, and use it to retrieve the assistant's answer
   */
  const sendSurveyDraftingAIMessage = async (currentMessage: MessageDto) => {
    if (isSendingMessage || !threadId) {
      return;
    }
    try {
      setIsSendingMessage(true);
      setErrorMessage(undefined); // Remove the error message every time a new message is sent
      setMessages((oldMessages) => [...oldMessages, currentMessage]);
      showLoading();
      const result =
        await apiClient.surveyDraftingApi.surveyDraftingControllerSendEngageAIMessage(
          { messageContent: currentMessage.content },
          threadId
        );

      if (result) {
        setHasInteractedYet(true);
        startRunPolling(result?.data);
      }
    } catch (error) {
      console.error(error);
      //  Remove the loading message in case of error
      const messagesWithoutLoading = removeLoadingFromArray(messages);
      setMessages([...messagesWithoutLoading, currentMessage]);
      setErrorMessage(i18n.t('engagementDetails.engageAi.apiError'));
    } finally {
      setIsSendingMessage(false);
    }
  };

  // Check the run status every 2 second and fetch messages when the status is `completed`
  const checkRunStatusAndFetchMessages = async (runId: string) => {
    if (!threadId) return;
    try {
      const checkRunStatusResult =
        await apiClient.surveyDraftingApi.surveyDraftingControllerGetRunStatus(
          runId,
          threadId
        );
      const currentStatus = checkRunStatusResult?.data;

      if (
        currentStatus === ChatStatus.inProgress ||
        currentStatus === ChatStatus.queued ||
        currentStatus === ChatStatus.requiresAction
      ) {
        queueCheckRunStatus(runId);
        return;
      }

      if (currentStatus === ChatStatus.completed) {
        setIsCheckingStatus(false);

        const getMessagesResult =
          await apiClient.surveyDraftingApi.surveyDraftingControllerGetEngageAIMessages(
            threadId
          );
        const messages = getMessagesResult.data.messages;
        setMessages([...initialSurveyDraftingMessages, ...messages]);
        if (getMessagesResult.data.newRunId) {
          startRunPolling(getMessagesResult.data.newRunId);
        }
      }
      if (isChatStatusError(currentStatus as ChatStatus)) {
        setIsCheckingStatus(false);

        setErrorMessage(i18n.t('engagementDetails.engageAi.chatgptError'));

        //  Remove the loading message in case of error
        const messagesWithoutLoading = messages.filter(
          (message) => typeof message.content === 'string'
        );
        setMessages([...messagesWithoutLoading]);
      }
    } catch (error) {
      setIsCheckingStatus(false);

      setErrorMessage(i18n.t('engagementDetails.engageAi.apiError'));
      const messagesWithoutLoading = messages.filter(
        (message) => typeof message.content === 'string'
      );
      setMessages([...messagesWithoutLoading]);
    }
  };

  const startRunPolling = (runId: string) => {
    showLoading();
    setIsCheckingStatus(true);
    queueCheckRunStatus(runId);
  };

  const queueCheckRunStatus = (runId: string) => {
    setTimeout(() => checkRunStatusAndFetchMessages(runId), 2000);
  };

  const removeLoadingFromArray = (messages: MessageDto[]) => {
    return messages.filter((message) => typeof message.content === 'string');
  };

  const showLoading = () => {
    setMessages((oldMessages) => [
      ...removeLoadingFromArray(oldMessages),
      {
        role: Role.assistant,
        content: <LoadingThreeDots />,
        createdAt: new Date().toDateString(),
      },
    ]);
  };

  return {
    messages,
    hasInteractedYet,
    isLoading,
    isSendingMessage,
    isCheckingStatus,
    errorMessage,
    startChatSession,
    sendSurveyDraftingAIMessage,
  };
};
