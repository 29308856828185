import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { FeatureFlag, FeatureFlagNameEnum } from '@keyops-cep/api-client';
import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';
import i18n from '../utils/i18n';

/**
 * Hook for feature flags
 * @param tenantId
 * @param feature
 * @deprecated use `useFeatureFlags` instead
 * @returns
 */
const useBEFeatureFlag = (tenantId: string, feature: FeatureFlagNameEnum) => {
  const toast = useToast();
  const response = useQuery({
    queryKey: [`be-feature-flag-${feature}`],
    queryFn: async () => {
      const response = await apiClient.tenantsApi.tenantControllerFeatureFlags(
        tenantId
      );
      return (
        response?.data?.find((el: FeatureFlag) => el.name === feature)
          ?.enabled || false
      );
    },
  });

  // Error handling for feature flag
  useEffect(() => {
    if (response.isError) {
      toast({
        title: i18n.t('errorComponent.title'),
        description: i18n.t('errorComponent.subTitle'),
        variant: 'subtle',
        isClosable: true,
        status: 'error',
        position: 'top-right',
      });
    }
  }, [response.isError, toast]);

  return response;
};

export default useBEFeatureFlag;
