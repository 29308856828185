import { IconType } from 'react-icons';
import { FiEdit, FiHome, FiLogOut } from 'react-icons/fi';
import { FeatureFlagNameEnum } from '@keyops-cep/api-client';

import i18n from '../../../utils/i18n';
import {
  DASHBOARD,
  DRAFT_SURVEY,
  LOGOUT,
} from '../../../utils/internal-routes.const';

export interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
  hidden?: boolean;
  requiredFeatureFlag?: FeatureFlagNameEnum;
}

export const SidebarNavItems: Array<LinkItemProps> = [
  {
    name: i18n.t('sidebar.sidebarNavItems.home'),
    icon: FiHome,
    path: DASHBOARD,
  },
  {
    name: i18n.t('sidebar.sidebarNavItems.draftSurvey'),
    icon: FiEdit,
    path: DRAFT_SURVEY,
    requiredFeatureFlag: FeatureFlagNameEnum.EngageAISurveyDrafting,
  },
];

export const LogoutSidebarNavItem: Array<LinkItemProps> = [
  {
    name: i18n.t('sidebar.sidebarNavItems.logout'),
    icon: FiLogOut,
    path: LOGOUT,
  },
];
