import React, { useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  Row,
  useReactTable,
} from '@tanstack/react-table';

import {
  dateFormatter,
  timeFormatter,
} from '../../../utils/functions/common-utils';
import { getVideoTime } from '../../../utils/functions/format-date';
import i18n from '../../../utils/i18n';
import {
  AdBoardEngagementFeedDto,
  AdBoardOutletContextType,
  AdBoardSectionGroupDto,
  isVideoMessageContext,
  SectionDto,
} from '../types';

const createColumns = (filterByUserId: boolean) => {
  const columnHelper = createColumnHelper<AdBoardEngagementFeedDto>();
  return [
    columnHelper.accessor('userId', {
      cell: (info) => <Box>{info.getValue()}</Box>,
      header: 'User id',
      // Custom filter for userId column
      filterFn: (row, columnId, filterValue) => {
        const userId = row.getValue(columnId);
        return userId === Number(filterValue); // Compare after converting filterValue to a number
      },
    }),
    columnHelper.accessor('participantName', {
      cell: (info) => (
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyItems={'flex-start'}
          gap={1}
          minW={140}
          data-testid="participant-name"
        >
          {/* // TODO: ask Jo is we need this link? */}
          {/* <Link
            href={'#'}
            color="keyops.blue"
            target="_blank"
            rel="noopener noreferrer"
          > */}
          {!filterByUserId && info.getValue()}
          {/* </Link> */}
          <Text>{dateFormatter(info.row.original.createdAt.toString())}</Text>
        </Box>
      ),
      size: 10,
      header: 'Participant name',
    }),
    columnHelper.accessor('content', {
      cell: (info) => (
        <Box display={'flex'} flexDir={'column'} gap={1} data-testid="content">
          {isVideoMessageContext(info.row.original.messageContext) &&
            getVideoTime(info.row.original.messageContext.videoTimeStamp)}
          <Text>
            {info.row.original.sectionType === 'attachment'
              ? i18n.t(
                  'adBoardDetails.engagement.attachmentReadReceiptString',
                  {
                    attachmentName: info.row.original.attachmentName,
                    openedDate: dateFormatter(
                      info.row.original.attachmentOpenedAt
                    ),
                    openedTime: timeFormatter(
                      info.row.original.attachmentOpenedAt
                    ),
                  }
                )
              : info.getValue()}
          </Text>
        </Box>
      ),
      size: 90,
      header: 'Content',
    }),
    columnHelper.accessor('sectionGroupId', {
      cell: (info) => <Box>{info.getValue()}</Box>,
      header: 'Section group id',
    }),
    columnHelper.accessor('sectionId', {
      cell: (info) => <Box>{info.getValue()}</Box>,
      header: 'Section id',
    }),
    columnHelper.accessor('discussionId', {
      cell: (info) => <Box>{info.getValue()}</Box>,
      header: 'Discussion id',
    }),
  ];
};

// Custom global filter function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const globalFilterFn = <TData extends object>(
  row: Row<AdBoardEngagementFeedDto>,
  columnId: string,
  filterValue: string
): boolean => {
  const { participantName, content, attachmentName, attachmentOpenedAt } =
    row.original;

  const lowerCaseFilterValue = filterValue.toLowerCase();

  return (
    participantName.toLowerCase().includes(lowerCaseFilterValue) ||
    (content?.toLowerCase().includes(lowerCaseFilterValue) ?? false) ||
    (attachmentName?.toLowerCase().includes(lowerCaseFilterValue) ?? false) ||
    (attachmentOpenedAt
      ? !!dateFormatter(attachmentOpenedAt)
          ?.toLowerCase()
          ?.includes(lowerCaseFilterValue)
      : false) ||
    (attachmentOpenedAt
      ? !!timeFormatter(attachmentOpenedAt)
          ?.toLowerCase()
          ?.includes(lowerCaseFilterValue)
      : false)
  );
};

const FeedTable = ({
  selectedGroup,
  selectedActivity,
  selectedDiscussionId,
  searchQuery,
  filterByUserId,
}: {
  selectedGroup: AdBoardSectionGroupDto;
  selectedActivity: SectionDto;
  selectedDiscussionId?: string;
  searchQuery?: string;
  filterByUserId?: string;
}) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { feedData } = useOutletContext<AdBoardOutletContextType>();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    const filters = [];
    if (filterByUserId)
      filters.push({
        id: 'userId',
        value: filterByUserId,
      });
    if (selectedGroup?.id)
      filters.push({
        id: 'sectionGroupId',
        value: selectedGroup?.id,
      });
    if (selectedActivity?.id)
      filters.push({
        id: 'sectionId',
        value: selectedActivity?.id,
      });
    if (selectedDiscussionId)
      filters.push({
        id: 'discussionId',
        value: selectedDiscussionId,
      });
    setColumnFilters(filters);
  }, [
    filterByUserId,
    selectedActivity?.id,
    selectedGroup?.id,
    selectedDiscussionId,
  ]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = createColumns(!!filterByUserId);
  // Use the table hook with global filter enabled
  const table = useReactTable({
    data: feedData,
    columns,
    state: {
      globalFilter: searchQuery,
      columnFilters,
      columnVisibility: {
        userId: false,
        sectionGroupId: false,
        sectionId: false,
        discussionId: false,
      },
      pagination,
    },
    globalFilterFn,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <TableContainer
      ref={tableRef}
      bg={useColorModeValue('white', 'gray.700')}
      mt={4}
      maxH={'100%'}
      overflowY={'scroll'}
    >
      <Table variant="unstyled" data-testid="feed-table">
        <Tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                fontSize={'sm'}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    style={{
                      whiteSpace: 'pre-wrap',
                      width: `${cell.column.getSize()}%`,
                    }}
                    placeContent={'flex-start'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td
                colSpan={columns.length}
                textAlign={'center'}
                color={'blue.700'}
                py={20}
              >
                {i18n.t('adBoardDetails.engagement.noData')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {table.getRowModel().rows?.length > 0 && (
        <HStack gap={2} mt={16} justifyContent={'center'}>
          <IconButton
            variant={'ghost'}
            colorScheme="blue"
            aria-label="Previous page"
            icon={<FiChevronLeft fontSize={22} />}
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          />
          {table.getPageCount() > 1 &&
            table.getState().pagination.pageIndex + 1 ===
              table.getPageCount() && (
              <Button
                variant={'ghost'}
                onClick={() =>
                  table.setPageIndex(table.getState().pagination.pageIndex - 1)
                }
              >
                {table.getState().pagination.pageIndex}
              </Button>
            )}
          <Button
            variant={'outline'}
            colorScheme={'blue'}
            onClick={() => table.setPageIndex(0)}
          >
            {table.getState().pagination.pageIndex + 1}
          </Button>
          {/* Conditionally render the next page if it's within range */}
          {table.getState().pagination.pageIndex + 1 < table.getPageCount() && (
            <Button
              variant={'ghost'}
              onClick={() =>
                table.setPageIndex(table.getState().pagination.pageIndex + 1)
              }
            >
              {table.getState().pagination.pageIndex + 2}
            </Button>
          )}
          <IconButton
            variant={'ghost'}
            colorScheme="blue"
            aria-label="Next page"
            icon={<FiChevronRight fontSize={22} />}
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          />
          <Select
            variant="outline"
            maxW={140}
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            aria-label="show"
          >
            {[10, 20, 30].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      )}
    </TableContainer>
  );
};

export { FeedTable };
