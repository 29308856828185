import i18n from '../i18n';

import { Role } from './engage-ai';

// EngageAI: First messages displayed to the customer
export const initialSurveyDraftingMessages = [
  {
    role: Role.assistant,
    content: i18n.t('surveyDrafting.initialMessages.first'),
    createdAt: new Date(Date.now()).toLocaleDateString(),
  },
];
